
.education-wrapper {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .education-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        .el-breadcrumb {
            line-height: 40px;
        }
    }
}
.el-link + .el-link {
    margin-left: 10px;
}
